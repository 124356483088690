// Tanner Fry
// tfry@monetagroup.com
// File contains the overall interface for the twilio chat integration.

import { useEffect, useRef, useState } from 'react';
import dingCorrect from '../../Assets/sounds/ding-correct.mp3';
import { ModalGeneralError } from '../../components/GeneralModals';
import Loader from '../../components/Loader';
import SoundPlayer from '../../components/SoundPlayer';
import { notifyInAppError, notifyInAppSuccess, notifyInAppSuccessWithOnClose } from '../../components/UtilitiesTS';
import { AdminType, EmployeeRole, IUserDetailsProps } from '../../interfaces/General';
import { ITwilioConversationsConversationProps, ITwilioConversationsMessageProps, ITwilioConversationsParticipantProps } from '../../interfaces/TwilioConversations';
import { TwilioConversationsRedux } from '../../redux/actions/TwilioConversations/TwilioConversations';
import { twilioConversationsConstants } from '../../redux/constants/TwilioConversationsConstant';
import { useAppDispatchTS, useAppSelectorTS } from '../../redux/hooksTS';
import { reduxSelectIsDarkMode } from '../../redux/slicesTS/UsersSlice';
import TwilioConversationInfo from './TwilioConversationInfo';
import TwilioConversationList from './TwilioConversationList';
import TwilioConversationSearch from './TwilioConversationSearch';
import TwilioHeader from './TwilioHeader';
import TwilioMessages from './TwilioMessages';
import { ModalTwilioDeleteTextParticipantFromConversation, ModalTwilioNewConversation } from './TwilioModals';

interface ITwilioDashboardProps {
    chatType: string;
}

const TwilioDashboard = ({
    chatType
}: ITwilioDashboardProps) => {
    // Setup
    const [twilioConversationsContentHeight, setTwilioConversationsContentHeight] = useState(0);
    const refTwilioConversationsContainer = useRef(null);
    const refTwilioConversationsHeader = useRef(null);
    const [loader, setLoader] = useState(true);
    const dispatch = useAppDispatchTS();
    // Themeing
    const isDarkMode = useAppSelectorTS(reduxSelectIsDarkMode);
    // Modals 
    const [showModalTwilioNewConversation, setShowModalTwilioNewConversation] = useState(false);
    const [showModalTwilioDeleteParticipantFromConversation, setShowModalTwilioDeleteParticipantFromConversation] = useState(false);
    const [showModalError, setShowModalError] = useState(false);
    const [showModalErrorText, setShowModalErrorText] = useState("");
    // Details
    const [userDetails, setUserDetails] = useState<IUserDetailsProps>(
        JSON.parse(localStorage.getItem("userDetails") || "")
    );
    const [userDetailsAsParticipant, setUserDetailsAsParticipant] = useState<ITwilioConversationsParticipantProps | null>(null);
    const [userConversations, setUserConversations] = useState<ITwilioConversationsConversationProps[]>([]);
    const [activeConversation, setActiveConversation] = useState<ITwilioConversationsConversationProps | null>(null);
    const [currentConversationMessagesPage, setCurrentConversationMessagesPage] = useState(1);
    // Switches
    const [showConversationInfo, setShowConversationInfo] = useState(false);
    const [showConversationSearch, setShowConversationSearch] = useState(false);
    const [messagesHasNextPage, setMessagesHasNextPage] = useState(false);
    const [messagesPaginatorClicked, setMessagesPaginatorClicked] = useState(false);
    const [isMessagingEnabled, setIsMessagingEnabled] = useState(false);
    const [noResponseFromServer, setNoResponseFromServer] = useState(false)
    const soundPlayerRef = useRef<{ play: () => void; pause: () => void }>(null);

    const playSoundDingCorrect = () => {
        if (soundPlayerRef.current) {
            soundPlayerRef.current.play();
        }
    };

    const pauseSoundDingCorrect = () => {
        if (soundPlayerRef.current) {
            soundPlayerRef.current.pause();
        }
    };

    useEffect(() => {
        // Clear active conversation when chat type changes
        setUserConversations([]);
        setActiveConversation(null);
        setCurrentConversationMessagesPage(1);
        setShowConversationInfo(false);
        setShowConversationSearch(false);
        setMessagesHasNextPage(false);
        setMessagesPaginatorClicked(false);
        setIsMessagingEnabled(false);
    }, [chatType]);

    useEffect(() => {
        console.log('userDetails', userDetails);
    }, [userDetails]);

    // Get user conversations
    useEffect(() => {
        dispatch(TwilioConversationsRedux.GetUserChatConversations(userDetails.twilio_conversations_user_identity)).then((response: any) => {
            // console.log('Get user conversations response for user ' + userDetails.twilio_conversations_user_identity + ':', response);
            if (response.type === twilioConversationsConstants.GET_USER_CONVERSATIONS_SUCCESS) {
                setUserConversations(response.data.conversations);
                console.log("Conversations: ", response.data.conversations);
                
                // Set conversation unread message indicators for each conversation
                // dispatch(TwilioConversationsRedux.GetU)
                setLoader(false);
            }
        });
    }, []);

    // Periodically call the API to get all conversation updates
    // TODO: Refactor based on chatType
    useEffect(() => {
        // Check if active conversation is set. If so, check if conversation has sms participant. If so, check if they've opted in
        // TODO: REFACTOR THIS BELOW TO GET MESSAGE ENABLING
        // if (activeConversation && activeConversation.has_text_message_binding_setup_with_participant) {
        //     const sms_participants = activeConversation.participants.filter((participant: ITwilioConversationsParticipantProps) => participant.messaging_binding?.type === 'sms');
        //     let shouldMessagingBeEnabled = true;
        //     console.log('sms_participants', sms_participants);
            
        //     sms_participants.forEach((participant: ITwilioConversationsParticipantProps) => {
        //         if (!participant.opt_in_status) {
        //             shouldMessagingBeEnabled = false;
        //         }
        //     });
        //     console.log('Should messaging be enabled: ', shouldMessagingBeEnabled);
            
        //     setIsMessagingEnabled(shouldMessagingBeEnabled);
        // } else if (activeConversation && !activeConversation.has_text_message_binding_setup_with_participant) {
        //     console.log('No text message binding setup with participant.');
            
        //     setIsMessagingEnabled(true);
        // } else {
        //     console.log('No active conversation set that has a text message binding setup with participant.');
        //     console.log('Active conversation: ', activeConversation);
            
        // }

        // Setup interval to call for user conversations periodically
        const interval = setInterval(() => {
            // Check conversations
            dispatch(TwilioConversationsRedux.GetUserChatConversations(userDetails.twilio_conversations_user_identity)).then(async (response: any) => {
                // Check and make sure response was given
                if (!response) {
                    notifyInAppError("Error getting your conversations: No response from the server.");
                    setNoResponseFromServer(prevState => prevState || true);

                    return;
                } else {
                    setNoResponseFromServer(prevState => {
                        if (prevState) {
                            notifyInAppSuccessWithOnClose("Connected back to the server!", () => {
                                setNoResponseFromServer(false);
                            });
                        }

                        return prevState; // Returning prevState ensures it remains unchanged if already true
                    });

                    if (response && response.type === twilioConversationsConstants.GET_USER_CONVERSATIONS_SUCCESS) {
                        setUserConversations(response.data.conversations);
                        // Update active conversation and it's messages if active conversation is set and a new message has been added
                        if (activeConversation) {
                            let potentialNewActiveConversation: ITwilioConversationsConversationProps = response.data.conversations.find((conversation: ITwilioConversationsConversationProps) => conversation.sid === activeConversation.sid);
                            if (!potentialNewActiveConversation) {
                                console.log('No potential new active conversation found.');

                                return;
                            }
                            await dispatch(TwilioConversationsRedux.GetUserConversationMessages(potentialNewActiveConversation.sid, 1, 20)).then((response: any) => {
                                if (response && response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_SUCCESS) {
                                    // Update potential new conversation messages
                                    potentialNewActiveConversation.messages = response.data.messages;
                                } else if (response && response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_FAILURE) {
                                    console.log('Error getting potential new conversation messages.');
                                } else {
                                    console.log('No response from server when getting potential new conversation messages.');
                                }
                            });

                            // Log comparison of indexes
                            if (activeConversation.messages && activeConversation.messages.length > 0 && potentialNewActiveConversation.messages 
                                && activeConversation.messages[activeConversation.messages.length - 1].index && (
                                activeConversation.messages[activeConversation.messages.length - 1].index
                                !== potentialNewActiveConversation.messages[potentialNewActiveConversation.messages.length - 1].index
                            )) {
                                console.log('New messages in active conversation, updating messages.');
                                if (potentialNewActiveConversation.messages[potentialNewActiveConversation.messages.length - 1].participant_sid !== userDetailsAsParticipant?.sid) {
                                    playSoundDingCorrect();
                                }
                                // Handle pulling active conversation data
                                handlePullCurrentPageOfMessages(activeConversation);
                            } else {
                                if (activeConversation.messages && activeConversation.messages.length == 0 && potentialNewActiveConversation.messages && potentialNewActiveConversation.messages.length > 0) {
                                    console.log('New messages in active conversation, updating messages.');
                                    if (potentialNewActiveConversation.messages[potentialNewActiveConversation.messages.length - 1].participant_sid !== userDetailsAsParticipant?.sid) {
                                        playSoundDingCorrect();
                                    }
                                    // Handle pulling active conversation data
                                    handlePullCurrentPageOfMessages(activeConversation);
                                }
                                // console.log('No new messages in active conversation, not updating messages.');
                                // TODO: Check modified date since some messages could be updated (such as read_by, editing a message -- which is a future thing, or other)
                            }
                        } else {
                            // console.log('No active conversation set, not updating messages.');
                        }
                    } else if (response && response.type === twilioConversationsConstants.GET_USER_CONVERSATIONS_FAILURE) {
                        notifyInAppError("Error getting your conversations: " + JSON.stringify(response.error.error));
                    }
                }
            });
        }, 5000);  // 10 seconds
        return () => clearInterval(interval);
    }, [activeConversation]);

    // Calculate height for twilio-conversations-content
    useEffect(() => {
        setTimeout(() => {
            if (refTwilioConversationsContainer.current && refTwilioConversationsHeader.current) {
                const twilioConversationsContainerHeight = refTwilioConversationsContainer.current['offsetHeight'];
                const twilioConversationsHeaderHeight = refTwilioConversationsHeader.current['offsetHeight'];
                setTwilioConversationsContentHeight(twilioConversationsContainerHeight - twilioConversationsHeaderHeight);
            }
        }, 500);
    }, [userDetails]);

    // Functions

    const handleCreateNewConversationClicked = () => {
        // Create popup that gives a form to create a new conversation
        setShowModalTwilioNewConversation(true);
    }

    const handleConversationClicked = (conversation: ITwilioConversationsConversationProps) => {
        if (activeConversation && activeConversation.sid === conversation.sid) {            
            setActiveConversation(null);
            return;
        }
        
        setActiveConversation(conversation);
        setCurrentConversationMessagesPage(1);
        setMessagesHasNextPage(false);
        setMessagesPaginatorClicked(false);
        // Get current user's participant details from conversation
        const participant = conversation.participants.find((participant: ITwilioConversationsParticipantProps) => 
            participant.identity === userDetails.twilio_conversations_user_identity
        );
        setUserDetailsAsParticipant(participant || null);

        // Get conversation messages from API
        // setLoader(true);
        dispatch(TwilioConversationsRedux.GetUserConversationMessages(conversation.sid, 1, 20)).then((response: any) => {
            // setLoader(false);
            console.log('response', response);
            if (response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_SUCCESS) {
                // Update active conversation messages
                const updatedConversation: ITwilioConversationsConversationProps = conversation ? {...conversation} : {} as ITwilioConversationsConversationProps;
                updatedConversation.messages = response.data.messages;
                setMessagesHasNextPage(response.data.has_next_page);                
                setActiveConversation(updatedConversation);
            } else if (response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_FAILURE) {
                console.log('Error getting conversation messages after update interval.');
            }
        });
    }

    // NOTE: This should only be called if active conversation messages has been updated
    const handlePullCurrentPageOfMessages = async (activeConversation: ITwilioConversationsConversationProps) => {
        if (currentConversationMessagesPage > 1) {
            let updatedConversation: ITwilioConversationsConversationProps = activeConversation ? {...activeConversation} : {} as ITwilioConversationsConversationProps;
            // Pull messages up to current page
            const promises = [];
            const messagesByPage: { [page: number]: ITwilioConversationsMessageProps[] } = {};
            for (let i = 1; i <= currentConversationMessagesPage; i++) {
                // Pull messages for page i and add them all together
                const promise = dispatch(TwilioConversationsRedux.GetUserConversationMessages(activeConversation.sid, i, 20)).then((response: any) => {
                    // console.log('On Message refresh pull next page of messages depending on user page at time of refresh - response: ', response);
                    // console.log("Current page: ", i);
                    // console.log("Current updated conversation messages: ", updatedConversation.messages, " on page ", i);

                    if (response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_SUCCESS) {
                        messagesByPage[i] = response.data.messages;
                    } else if (response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_FAILURE) {
                        console.log('Error getting conversation messages after update interval.');
                    }
                });

                promises.push(promise);
            }

            // Wait for all promises to resolve
            await Promise.all(promises).then(() => {
                // Sort messages by page number and combine them
                const sortedMessages = Object.keys(messagesByPage)
                    .map(Number) // Convert keys to numbers
                    .sort((a, b) => b - a) // Sort numerically in ascending order
                    .reduce((messageList: ITwilioConversationsMessageProps[], page) => {
                        return [...messageList, ...messagesByPage[page]];
                }, []);

                updatedConversation.messages = sortedMessages;
                if (!activeConversation) return;

                // console.log("Current vs updated conversation: ", activeConversation, updatedConversation);

                // Compare current conversation to updated conversation to see if any data has changed
                if (JSON.stringify(activeConversation) !== JSON.stringify(updatedConversation)) {
                    // console.log("Setting active conversation to updated conversation: ", updatedConversation);
                    
                    setActiveConversation(updatedConversation);
                    const sms_participants = activeConversation.participants.filter((participant: ITwilioConversationsParticipantProps) => participant.messaging_binding?.type === 'sms');
                    let shouldMessagingBeEnabled = true;
                    sms_participants.forEach((participant: ITwilioConversationsParticipantProps) => {
                        if (!participant.opt_in_status) {
                            shouldMessagingBeEnabled = false;
                        }
                    });
                    setIsMessagingEnabled(shouldMessagingBeEnabled);
                } else {
                    // console.log("Current conversation messages: ", activeConversation.messages);
                    // console.log("Updated conversation messages: ", updatedConversation.messages);
                    // console.log("Current conversation is the same as updated conversation, no need to update.");
                }
            });
        } else {
            // Pull conversation messages and update if new data is different than current
            // Pull messages for page 2 and add to current messages
            let updatedConversation: ITwilioConversationsConversationProps = activeConversation ? {...activeConversation} : {} as ITwilioConversationsConversationProps;
            let hasNextPage = false;
            dispatch(TwilioConversationsRedux.GetUserConversationMessages(activeConversation.sid, 1, 20)).then((response: any) => {
                // console.log('On Message refresh pull page 1 of messages - response: ', response);
                if (response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_SUCCESS) {
                    // Set updated conversation messages to response data messages
                    updatedConversation.messages = response.data.messages;
                    hasNextPage = response.data.has_next_page;

                    // Compare current conversation to updated conversation to see if any data has changed
                    // console.log("Current vs updated conversation: ", activeConversation, updatedConversation);
                    
                    if (!activeConversation) return;
                    if (JSON.stringify(activeConversation) !== JSON.stringify(updatedConversation)) {
                        // console.log("Setting active conversation to updated conversation: ", updatedConversation);
                        
                        setActiveConversation(updatedConversation);
                        setMessagesHasNextPage(hasNextPage);
                        const sms_participants = activeConversation.participants.filter((participant: ITwilioConversationsParticipantProps) => participant.messaging_binding?.type === 'sms');
                        let shouldMessagingBeEnabled = true;
                        sms_participants.forEach((participant: ITwilioConversationsParticipantProps) => {
                            if (!participant.opt_in_status) {
                                shouldMessagingBeEnabled = false;
                            }
                        });
                        setIsMessagingEnabled(shouldMessagingBeEnabled);
                    } else {
                        // console.log("Current conversation messages: ", activeConversation.messages);
                        // console.log("Updated conversation messages: ", updatedConversation.messages);
                        // console.log("Current conversation is the same as updated conversation, no need to update.");
                    }
                } else if (response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_FAILURE) {
                    console.log('Error getting conversation messages after conversation clicked.');
                }
            });
        }
    }
    const handlePullNextPageOfMessages = (conversation: ITwilioConversationsConversationProps) => {
        // Get conversation messages from API
        setMessagesPaginatorClicked(true);
        let updatedConversation: ITwilioConversationsConversationProps = conversation ? {...conversation} : {} as ITwilioConversationsConversationProps;
        let hasNextPage = false;

        // Pull new data
        dispatch(TwilioConversationsRedux.GetUserConversationMessages(conversation.sid, currentConversationMessagesPage + 1, 20)).then((response: any) => {
            console.log('Pull next page of messages response: ', response);
            if (response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_SUCCESS) {
                // Keep original messages and add new messages to the beginning
                updatedConversation.messages = updatedConversation.messages ? [...response.data.messages, ...updatedConversation.messages] : response.data.messages;
                hasNextPage = response.data.has_next_page;
            } else if (response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_FAILURE) {
                console.log('Error getting conversation messages after conversation clicked.');
            }

            if (!activeConversation) return;

            // Compare current conversation to updated conversation to see if any data has changed
            if (JSON.stringify(conversation) !== JSON.stringify(updatedConversation)) {
                setActiveConversation(updatedConversation);
                setMessagesHasNextPage(hasNextPage);
                setCurrentConversationMessagesPage(currentConversationMessagesPage + 1);
            } else {
                console.log("Current conversation messages: ", conversation.messages);
                console.log("Updated conversation messages: ", updatedConversation.messages);
                console.log("Current conversation is the same as updated conversation, no need to update.");
            }
        });
    }

    const handleCreateNewMessage = (conversationId: string, message: string, participantSid: string, participantName: string) => {
        // Fire off new message dispatch
        console.log('Creating new message for conversation ' + activeConversation?.friendly_name + ' with message: ' + message);
        dispatch(TwilioConversationsRedux.PostNewConversationMessage(conversationId, message, participantSid, participantName)).then((response: any) => {
            console.log('new message response', response);
            if (response.type === twilioConversationsConstants.POST_NEW_CONVERSATION_MESSAGE_SUCCESS) {
                // Get conversation messages from API
                dispatch(TwilioConversationsRedux.GetUserConversationMessages(conversationId)).then((response: any) => {
                    console.log('after new message, get conversation messages response', response);
                    if (response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_SUCCESS) {
                        // Update active conversation messages
                        const updatedConversation: ITwilioConversationsConversationProps = activeConversation ? {...activeConversation} : {} as ITwilioConversationsConversationProps;
                        updatedConversation.messages = response.data.messages;
                        setActiveConversation(updatedConversation);
                        // Update user conversations locally with new messages
                        const updatedUserConversations = userConversations.map((conversation: ITwilioConversationsConversationProps) => {
                            if (conversation.sid === conversationId) {
                                conversation.messages = response.data.messages;
                            }
                            return conversation;
                        });
                        setUserConversations(updatedUserConversations);
                    } else if (response.type === twilioConversationsConstants.GET_CONVERSATION_MESSAGES_FAILURE) {
                        console.log('Error getting updated conversation messages after new conversation message created.');
                    }
                });
            } else if (response.type === twilioConversationsConstants.POST_NEW_CONVERSATION_MESSAGE_FAILURE) {
                console.log('Error creating new conversation message.');
            } else if (response.type === twilioConversationsConstants.POST_NEW_CONVERSATION_MESSAGE_CONFLICT) {
                notifyInAppError("Conflict when creating new conversation message: " + JSON.stringify(response.data.error))
                console.log('Conflict creating new conversation message: ', response.data.error);
                // setShowModalErrorText(response.data.error);
                // setShowModalError(true);
            }
        });
    }

    const handleConversationMuteClicked = (conversation: ITwilioConversationsConversationProps) => {
        // Fire off new dispatch for current user to mute the given conversation
    }

    const handleConversationLeaveClicked = (conversation: ITwilioConversationsConversationProps) => {
        // Get current users participant sid from conversation
        const userParticipant = conversation.participants.find((participant: ITwilioConversationsParticipantProps) => 
            participant.identity === userDetails.twilio_conversations_user_identity
        );
        if (userParticipant && userParticipant.sid) {
            // Fire off new dispatch for current user to leave the given conversation
            console.log('Leaving conversation ' + conversation.friendly_name);
            setLoader(true);
            dispatch(TwilioConversationsRedux.PostLeaveUserChatConversation(conversation.sid, userParticipant?.sid)).then((response: any) => {
                setLoader(false);
                console.log('response', response);
                if (response.type === twilioConversationsConstants.POST_LEAVE_CONVERSATION_SUCCESS) {
                    notifyInAppSuccess("Successfully left the conversation!");
                    // Update user conversations locally
                    const updatedUserConversations = userConversations.filter((conversation: ITwilioConversationsConversationProps) => conversation.sid !== response.data.conversation_id);
                    setUserConversations(updatedUserConversations);
                    // Update active conversation locally
                    if (activeConversation && activeConversation.sid === response.data.conversation_id) {
                        setActiveConversation(null);
                    }
                } else if (response.type === twilioConversationsConstants.POST_LEAVE_CONVERSATION_FAILURE) {
                    notifyInAppError("Error when leaving conversation: " + JSON.stringify(response));
                    console.log('Error leaving conversation.');
                }
            });
        } else {
            notifyInAppError("Error: Could not find participant details for current user in conversation.");
        }
    }        

    return (
        <div 
            className='twilio-conversations-container h-100 overflow-auto' 
            ref={refTwilioConversationsContainer} 
            style={{
                // Only apply background if dark mode is enabled
                backgroundColor: isDarkMode ? "#15222b" : "",
            }}
        >
            <div 
                className={"twilio-conversations-header heading-wrap d-flex align-items-center justify-content-between px-16 px-md-24 chat-type-" + chatType} 
                ref={refTwilioConversationsHeader}
                style={{
                    // Only apply background if dark mode is enabled
                    backgroundColor: isDarkMode ? "#15222b" : "",
                }}
            >
                <TwilioHeader 
                    chatType={chatType}
                    advisor_or_staff_number={userDetails.twilio_assigned_proxy_number ? userDetails.twilio_assigned_proxy_number : ""}
                />
            </div>
            <div className='twilio-conversations-content d-flex w-100 position-relative' style={{height: twilioConversationsContentHeight}}>
                {loader ? (
                    <Loader 
                        hardSetActive={loader}
                        startActiveTime={3000}
                    />
                ) : (
                    <>
                        <TwilioConversationList 
                            userConversations={userConversations} 
                            handleNewConversationClicked={handleCreateNewConversationClicked} 
                            handleConversationClicked={handleConversationClicked}
                            handleConversationMuteClicked={handleConversationMuteClicked}
                            handleConversationLeaveClicked={handleConversationLeaveClicked}
                            setShowModalError={setShowModalError}
                            setShowModalErrorText={setShowModalErrorText}
                            activeConversation={activeConversation}
                            chatType={chatType}
                            userDetails={userDetails}
                        />
                        <TwilioMessages 
                            conversation={activeConversation}
                            userDetailsAsParticipant={userDetailsAsParticipant}
                            handleCreateNewMessage={handleCreateNewMessage}
                            handlePullNextPageOfMessages={handlePullNextPageOfMessages}
                            paginatorClicked={messagesPaginatorClicked}
                            setPaginatorClicked={setMessagesPaginatorClicked}
                            messagesHasNextPage={messagesHasNextPage}
                            setShowConversationInfo={setShowConversationInfo}
                            setShowConversationSearch={setShowConversationSearch}
                            isMessagingEnabled={isMessagingEnabled}
                            userDetails={userDetails}
                        />
                        {showConversationInfo && activeConversation && (
                            <TwilioConversationInfo 
                                userDetails={userDetails}
                                activeConversation={activeConversation}
                                setActiveConversation={setActiveConversation}
                                userConversations={userConversations}
                                setUserConversations={setUserConversations}
                                setShowConversationInfo={setShowConversationInfo}
                                setShowModalError={setShowModalError}
                                setShowModalErrorText={setShowModalErrorText}
                                handleConversationLeaveClicked={handleConversationLeaveClicked}
                                setShowModalTwilioDeleteParticipantFromConversation={setShowModalTwilioDeleteParticipantFromConversation}
                                chatType={chatType}
                            />
                        )}
                        {showConversationSearch && activeConversation && (
                            <TwilioConversationSearch 
                                userConversation={activeConversation}
                                setShowModalError={setShowModalError}
                                setShowModalErrorText={setShowModalErrorText}
                                setShowConversationSearch={setShowConversationSearch}
                            />
                        )}
                    </>
                )}
            </div>
            <ModalTwilioNewConversation
                userDetails={userDetails}
                isShowing={showModalTwilioNewConversation}
                handleClose={() => {setShowModalTwilioNewConversation(false)}}
                chatType={chatType}
            />
            {userDetails?.employee?.role === EmployeeRole.STAFF && userDetails.admin_type === AdminType.FIRM_ADMIN && userDetails.email === "tfry@monetagroup.com" && (
                <ModalTwilioDeleteTextParticipantFromConversation
                    isShowing={showModalTwilioDeleteParticipantFromConversation}
                    handleClose={() => {setShowModalTwilioDeleteParticipantFromConversation(false)}}
                    // Try to pass active conversation sid and text participant sid
                    presetActiveConversationSid={activeConversation?.sid}
                    // presetParticipantSid={userDetailsAsParticipant?.sid}
                />
            )}
            <ModalGeneralError 
                isShowing={showModalError}
                setIsShowing={setShowModalError}
                errorMessage={showModalErrorText}
                setErrorMessage={setShowModalErrorText}
            />
            <SoundPlayer ref={soundPlayerRef} url={dingCorrect} />
        </div>
    );
}

export default TwilioDashboard;