// Tanner Fry
// tfry@monetagroup.com
// File contains the messages component for the twilio dashboard. This handles and displays messages for a conversation.

import { MessageOutlined } from "@ant-design/icons";
import DOMPurify from "dompurify";
import React, { useEffect, useRef, useState } from "react";
import { convertISOToTime, SystemMessage } from "../../components/UtilitiesTS";
import { IUserDetailsProps } from "../../interfaces/General";
import { ITwilioConversationsConversationProps, ITwilioConversationsMessageProps, ITwilioConversationsParticipantProps } from "../../interfaces/TwilioConversations";
import { TwilioConversationsRedux } from "../../redux/actions/TwilioConversations/TwilioConversations";
import { useAppDispatchTS } from "../../redux/hooksTS";
import TCUtilities from "./TwilioConversationUtilities";


interface ITwilioMessagesProps {
    conversation: ITwilioConversationsConversationProps | null;
    userDetailsAsParticipant: ITwilioConversationsParticipantProps | null;
    handleCreateNewMessage: (conversationId: string, message: string, participantSid: string, participantName: string) => void;
    handlePullNextPageOfMessages: (conversation: ITwilioConversationsConversationProps) => void;
    paginatorClicked?: boolean;
    setPaginatorClicked: (clicked: boolean) => void;
    messagesHasNextPage: boolean;
    setShowConversationInfo: (show: boolean) => void;
    setShowConversationSearch: (show: boolean) => void;
    isMessagingEnabled: boolean;
    userDetails: IUserDetailsProps;
}

const TwilioMessages = ({
    conversation,
    userDetailsAsParticipant,
    handleCreateNewMessage,
    handlePullNextPageOfMessages,
    paginatorClicked,
    setPaginatorClicked,
    messagesHasNextPage,
    setShowConversationInfo,
    setShowConversationSearch,
    isMessagingEnabled,
    userDetails,
}: ITwilioMessagesProps) => {
    const [newMessage, setNewMessage] = useState<string>('');
    const [newMessageValidationText, setNewMessageValidationText] = useState<string>('');
    const [hasUserScrolled, setHasUserScrolled] = useState<boolean>(false);
    const [isMessageTextAreaEnabled, setIsMessageTextAreaEnabled] = useState<boolean>(true);
    const messageScrollEndRef = useRef<HTMLDivElement>(null);
    const messageListRef = useRef<HTMLDivElement>(null);
    const antdMessageIconProps = {
        className: 'icon-32',
        onPointerEnterCapture: () => {},
        onPointerLeaveCapture: () => {},
    };
    const dispatch = useAppDispatchTS();

    const handleScroll = () => {
        setHasUserScrolled(true);
        // If messageScrollEndRef is in view, set hasUserScrolled to false
        if (messageScrollEndRef.current && messageScrollEndRef.current.getBoundingClientRect().top <= window.innerHeight) {
            setHasUserScrolled(false);
        }
    }

    const scrollToBottom = () => {
        messageScrollEndRef.current?.scrollIntoView({ behavior: "smooth" });
        setHasUserScrolled(false);
    };

    useEffect(() => {
        // Don't scroll to the bottom if paginator was clicked
        if (!paginatorClicked) {
            // Check if the user has scrolled at all. If so, don't scroll to the bottom
            if (hasUserScrolled) {
                return;
            }
            scrollToBottom();
        } else {
            setPaginatorClicked(false);
        }

        // Determine if any sms participants in conversation are opted in. If so, allow messaging
        const sms_participants = conversation?.participants.filter((participant) => {
            return participant.messaging_binding?.type === 'sms';
        });
        let has_opt_in_participant = false
        if (sms_participants && sms_participants.length > 0) {
            const sms_participant = sms_participants[0];
            if (sms_participant.opt_in_status === true) {
                has_opt_in_participant = true;
                console.log("Participant is opted in to conversation");
            } else {
                console.log("Participant is not opted in to conversation");
            }
        }
        // setIsMessageTextAreaEnabled(has_opt_in_participant);

        // Add current user to "read_by" for each message in conversation if not already in it
        conversation?.messages?.map((message) => {
            if (!message.attributes.read_by?.includes(userDetails.twilio_conversations_user_identity)) {
                dispatch(TwilioConversationsRedux.UpdateUserMessageReadBy(message.sid, userDetails.twilio_conversations_user_identity));
            }
        })
    }, [conversation]);

    return (
        <div className='twilio-conversations-messages flex-grow-1'>
            <div className='messages-header d-md-flex align-items-center justify-content-between'>
                <h5 className='conversation-title m-0'>{conversation?.friendly_name}</h5>
                <div className='conversation-actions d-flex'>
                    {/* Search icon */}
                    <span 
                        className='icon-32 cursor-pointer'
                        onClick={() => {
                            setShowConversationInfo(false);
                            setShowConversationSearch(true);
                        }}
                        style={conversation ? {display: 'block'} : {display: 'none'}}
                    >
                        <em className='icon-search-outline fs-24'></em>
                    </span>
                    {/* Settings icon */}
                    <span 
                        className='icon-32 cursor-pointer '
                        onClick={() => {
                            setShowConversationInfo(true);
                            setShowConversationSearch(false);
                        }}
                        style={conversation ? {display: 'block'} : {display: 'none'}}
                    >
                        <em className='icon-info-circle-outline fs-24'></em>
                    </span>
                </div>
            </div>
            <div className='messages-list flex-grow-1' ref={messageListRef} onScroll={() => {handleScroll()}}>
                {/* Messages */}
                {/* TODO: Add message styling for current user messages vs other user messages
                TODO: Add message status
                TODO: Add message media
                TODO: Add message gifs
                TODO: Add message actions
                TODO: Add message reactions
                TODO: Add message replies
                TODO: Add message mentions
                TODO: Add message styling for different message types
                TODO: Add message styling for different message statuses */}
                {conversation && messagesHasNextPage && (
                    <div className="pageinator">
                        <button
                            className="btn-paginator"
                            onClick={() => {
                                if (conversation) {
                                    handlePullNextPageOfMessages(conversation);
                                }
                            }}
                        >
                            Load previous messages
                        </button>
                    </div>
                )}
                {conversation && conversation.messages && userDetailsAsParticipant && conversation.messages.length > 0 ? (
                    conversation.messages.map((message: ITwilioConversationsMessageProps, index) => {
                        if (!conversation.messages) return null;
                        const previousMessage = index > 0 ? conversation.messages[index - 1] : undefined;
                        const dateSeparator = TCUtilities.getDateSeparator(message, previousMessage);
                        
                        return (
                            // Return a date separator if the current message doesn't have the same date as the previous message
                            <React.Fragment key={index}>
                                {dateSeparator && (
                                    <div className='date-separator'>
                                        <div className='line'></div>
                                        <span>{dateSeparator}</span>
                                        <div className='line'></div>
                                    </div>
                                )}
                                {message.author === 'system' ? (
                                    <SystemMessage message={message.body} userTimeZone={userDetails.timezone.live_meeting} />
                                ) : (
                                    <div className={'message-item ' + ((TCUtilities.isMessageFromCurrentUser(message, userDetailsAsParticipant.sid)) ? 'current-user' : 'other-user')}>
                                        <div className='message-item-image'>
                                            {message.profile_image ? (
                                                <img src={message.profile_image} alt='Profile' />
                                            ) : (
                                                <em className='icon-user'></em>
                                            )}
                                        </div>
                                        <div className='message-item-details'>
                                            <div className="message-author">
                                                {!TCUtilities.isMessageFromCurrentUser(message, userDetailsAsParticipant.sid) && (
                                                    // Check if the author is a phone number or not. If so, format the number for display.
                                                    message.author.startsWith('+') ? (
                                                        <>
                                                            {/* NOTE: We've changed showing numbers in message to showing conversation name as we're assuming most people will
                                                                name the conversation after who they're texting. This is also utilized in TwilioConversationSearch for message author.
                                                            */}
                                                            {/* <h6>{TCUtilities.formatPhoneNumber(message.author)}</h6> */}
                                                            <h6>{ conversation.friendly_name }</h6>
                                                            {!TCUtilities.isMessageFromCurrentUser(message, userDetailsAsParticipant.sid) 
                                                                && message.attributes.text_user_opt_in_at_time_of_message === false && (
                                                                    <div className="message-not-opted-in">
                                                                        Not opted in to conversation
                                                                    </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <h6>{message.author}</h6>
                                                    )
                                                )}
                                            </div>
                                            <div className="message-content">
                                                {TCUtilities.isMessageFromCurrentUser(message, userDetailsAsParticipant.sid) && (
                                                    <div className="message-time">
                                                        {message.created_at === message.modified_at ? (
                                                            convertISOToTime(message.created_at)
                                                        ) : (
                                                            convertISOToTime(message.modified_at)
                                                        )}
                                                    </div>
                                                )}
                                                <div className="message-body-wrapper">
                                                    <div 
                                                        className="message-body"
                                                        // Sanitize and preserve special characters in Twilio message with HTML elements. 
                                                        // Also, allow us to modify the message body to add target="_blank" to links.
                                                        dangerouslySetInnerHTML={{
                                                            __html: TCUtilities.addTargetBlankToLinks(DOMPurify.sanitize(
                                                                TCUtilities.preserveSpecialCharactersInTwilioMessageWithHTMLElements(
                                                                    message.body
                                                                )
                                                            ))
                                                        }}
                                                    />
                                                </div>
                                                {!TCUtilities.isMessageFromCurrentUser(message, userDetailsAsParticipant.sid) && (
                                                    <div className="message-time">
                                                        {/* Always created_at, modified_at should not be used */}
                                                        {convertISOToTime(message.created_at)}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>  
                                )}
                            </React.Fragment>
                        );
                    })
                ): (
                    <>
                        {conversation && conversation.messages && conversation.messages.length === 0 ? (
                            <div className='no-messages'>
                                <MessageOutlined {...antdMessageIconProps} />
                                <h5>No messages at this time</h5>
                            </div>
                        ) : (
                            <div className='no-messages'>
                                <MessageOutlined {...antdMessageIconProps} />
                                <h5>Select a conversation to get started</h5>
                            </div>
                        )}
                    </>
                )}
                <div ref={messageScrollEndRef}></div>
            </div>
            <div className='messages-footer'>
                {/* {(conversation && participant) ? ( */}
                {(conversation && userDetailsAsParticipant && isMessageTextAreaEnabled) ? (
                    <div className="user-input">
                        <textarea 
                            className='message-input' 
                            placeholder='Type a message'
                            value={newMessage}
                            autoFocus
                            onChange={(e) => {
                                e.preventDefault();

                                // Set the new message
                                if (e.target.value.length > 1600) {
                                    setNewMessageValidationText('Message must be between 1 and 1600 characters');
                                } else {
                                    setNewMessage(e.target.value);
                                    setNewMessageValidationText('');
                                }
                            }}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey && conversation && userDetailsAsParticipant) {
                                    e.preventDefault();
                                    // Other conditions to check before sending the message
                                    if ((newMessage === '' || newMessage.length === 0 || newMessage.trim() === '') || newMessage.length > 1600 || newMessage === null || newMessage === undefined) {
                                        // Notify user of error and don't send message
                                        if (newMessage === '' || newMessage.length === 0 || newMessage.trim() === '') {
                                            setNewMessageValidationText('Message cannot be empty');
                                            setNewMessage('');
                                        } else if (newMessage.length > 1600) {
                                            setNewMessageValidationText('Message cannot be longer than 1600 characters');
                                        } else {
                                            setNewMessageValidationText('UI Error: Message null or undefined. Please notify support.');
                                        }
                                    } else {
                                        console.log("New message length: ", newMessage.length);
                                        
                                        handleCreateNewMessage(conversation.sid, newMessage, userDetailsAsParticipant.sid, userDetailsAsParticipant.name);
                                        setNewMessage('');
                                        setNewMessageValidationText('');
                                    }
                                }
                            }}

                            // TODO: Finish experimental feature below for best user experience
                            // onKeyUp={(e) => {
                            //     if (e.key === 'Enter' && !e.shiftKey && conversation && participant) {
                            //         e.preventDefault();
                            //         if (newMessage === '') {
                            //             return;
                            //             // Do nothing if the message is empty
                            //             // TODO: Add error message
                            //         }
                            //         handleCreateNewMessage(conversation.sid, newMessage, participant.sid, participant.name);
                            //         setNewMessage('');

                            //     // NOTE: If we want complete control over the keys and how they work, we can use the following code. This was
                            //     //       experimental in order to try and have the enter key automatically send the message instead of it acting
                            //     //       like a newline and then the message sending immediately after it.
                            //     } else {
                            //         // Make sure no special keys are being pressed
                            //         if (e.key === 'Enter' || e.key === 'Shift' || e.key === 'Control' || e.key === 'Alt' ||
                            //             e.key === 'Meta' || e.key === 'CapsLock' || e.key === 'Tab' || e.key === 'Escape' ||
                            //             e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'ArrowUp' || e.key === 'ArrowDown' ||
                            //             e.key === 'PageUp' || e.key === 'PageDown' || e.key === 'Home' || e.key === 'End' ||
                            //             e.key === 'Insert' || e.key === 'Delete' || e.key === 'Backspace' || e.key === 'F1' ||
                            //             e.key === 'F2' || e.key === 'F3' || e.key === 'F4' || e.key === 'F5' || e.key === 'F6' ||
                            //             e.key === 'F7' || e.key === 'F8' || e.key === 'F9' || e.key === 'F10' || e.key === 'F11' ||
                            //             e.key === 'F12' || e.key === 'PrintScreen' || e.key === 'ScrollLock' || e.key === 'Pause' ||
                            //             e.key === 'ContextMenu' || e.key === 'NumLock' || e.key === 'Clear' || e.key === 'Help' || e.key === 'a'
                            //         ) {
                            //             e.preventDefault();
                            //             console.log("Target data: ", e.target)
                            //             console.log("Event data: ", e);
                                        
                            //             // Backspace functionality
                            //             if (e.key === 'Backspace') {
                            //                 // Check if the message is empty
                            //                 if (newMessage === '') {
                            //                     return;
                            //                 }

                            //                 // TODO: Check if the target is selected. If so, remove the selected text
                            //                 // NOTE: Looks like we may have to implement refs to get selected data off the native textarea element
                                            
                            //                 // Remove the last character
                            //                 setNewMessage(newMessage.slice(0, -1));
                            //                 return;
                            //             }
                            //             // Select all functionality
                            //             if (e.key === 'a' && (e.ctrlKey || e.metaKey)) {
                            //                 // Select all text in the message input
                            //                 (e.target as HTMLInputElement).select();
                            //             }
                            //         } else {
                            //             // Set the new message
                            //             setNewMessage(newMessage + e.key);
                            //         }
                            //     }
                            // }}
                            // onKeyDownCapture={(e) => {
                            //     if (e.key === 'Backspace') {
                            //         e.preventDefault();
                            //         // Remove the last character from the message
                            //         setNewMessage((prevMessage) => prevMessage.slice(0, -1));
                            //     }
                            // }}
                            // onPaste={(e) => {
                            //     e.preventDefault();
                            //     // Get the pasted text
                            //     const pastedText = e.clipboardData.getData('text');
                            //     // Set the new message
                            //     setNewMessage(newMessage + pastedText);
                            // }}
                        />
                        <button
                            className='btn btn-success'
                            onClick={() => {
                                // Other conditions to check before sending the message
                                if ((newMessage === '' || newMessage.length === 0 || newMessage.trim() === '') || newMessage.length > 1600 || newMessage === null || newMessage === undefined) {
                                    // Notify user of error and don't send message
                                    if (newMessage === '' || newMessage.length === 0 || newMessage.trim() === '') {
                                        setNewMessageValidationText('Message cannot be empty');
                                        setNewMessage('');
                                    } else if (newMessage.length > 1600) {
                                        setNewMessageValidationText('Message cannot be longer than 1600 characters');
                                    } else {
                                        setNewMessageValidationText('UI Error: Message null or undefined. Please notify support.');
                                    }
                                } else {
                                    console.log("New message length: ", newMessage.length);
                                    
                                    handleCreateNewMessage(conversation.sid, newMessage, userDetailsAsParticipant.sid, userDetailsAsParticipant.name);
                                    setNewMessage('');
                                    setNewMessageValidationText('');
                                }
                            }}
                        >
                            Send
                        </button>
                    </div>
                ) : (
                    <div className="user-input">
                        <textarea 
                            className='message-input disabled'
                            placeholder='Type a message'
                            disabled
                        />
                        <button
                            className='btn btn-success disabled'
                        >
                            Send
                        </button>
                    </div>
                )}
                <div className="text-danger px-24">{newMessageValidationText}</div>
            </div>
        </div>
    );
}

export default TwilioMessages;