import React, { useEffect, useRef, useState } from 'react'
import { Outlet } from "react-router-dom";
import Header from './Header';
import Footer from './Footer';
import SideBar from './SideBar';
import { useLocation } from 'react-router-dom';

const Mainlayout = () => {
	const [toggleMobileMenu, settoggleMobileMenu] = useState(false);
	const headerRef = useRef(null);
	const [contentHeight, setContentHeight] = useState(0);
	const togglebtn = () => {
		settoggleMobileMenu(!toggleMobileMenu);
	}
	const location = useLocation();

	useEffect(() => {
		if (headerRef.current) {
			// Set content height 100% - headerRef height
			setContentHeight(window.innerHeight - headerRef.current.clientHeight);
		}
	}, [headerRef, location]);

	return (
		<>
			<Header togglebtn={togglebtn} headerRef={headerRef} />
			<div className='d-flex content-wrap overflow-auto' style={{ height: contentHeight }}>
				<SideBar toggleMobileMenu={toggleMobileMenu} togglebtn={togglebtn} />
				<div className='flex-fill main-content-bg content-right overflow-auto'>
					<Outlet />
				</div>
			</div>
			{/* <Footer /> */}
		</>
	)
}

export default Mainlayout;